import React, { FunctionComponent } from 'react'

import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import {
  Button,
  Collapse,
  Container,
  Flex,
  Grid,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Tfoot,
  useBreakpointValue,
} from '@chakra-ui/react'
import { formattedSessions } from '../../utils'

const OrderReceivedPage: FunctionComponent = () => {
  if (typeof window === 'undefined') {
    return (
      <Layout hasCart={false}>
        <SEO title="Order Received" />
      </Layout>
    )
  }

  const isSmallScreen = useBreakpointValue({ base: true, md: false })
  const {
    billing,
    discountTotal,
    lineItems,
    orderNumber,
    paymentMethodTitle,
    subtotal,
    total,
  } = window.history.state

  return (
    <Layout hasCart={false}>
      <SEO title="Order Received" />
      <Container maxW="4xl">
        <Grid gridGap="8">
          <Heading>{`Thankyou for your order...`}</Heading>
          <Text>
            A confirmation email for{' '}
            <Text as="span" fontWeight="bold">
              order #{orderNumber}
            </Text>{' '}
            has been sent to{' '}
            <Text as="span" fontWeight="bold">
              {billing.email}
            </Text>
          </Text>
          <Table>
            <Tbody>
              {lineItems.nodes.map((node, i: number) => {
                const {
                  product: {
                    courseDetails,
                    name,
                    onSale,
                    price,
                    regularPrice,
                    salePrice,
                  },
                  quantity,
                  subtotal,
                } = node || {}
                return (
                  <Tr key={i}>
                    {isSmallScreen ? (
                      <>
                        <Td>
                          <Grid gridGap="4">
                            <Grid>
                              <Text fontWeight="bold">{name}</Text>
                              <Text>
                                {formattedSessions(courseDetails?.sessions)}
                              </Text>
                              <Text>
                                {courseDetails?.presentationMethod === 'Online'
                                  ? courseDetails?.presentationMethod
                                  : courseDetails?.locationShortname}
                              </Text>
                            </Grid>
                            <Grid
                              alignItems="center"
                              gridGap="8"
                              gridTemplateColumns="auto 1fr"
                            >
                              <Grid gridGap="1">
                                <Text
                                  textDecoration={
                                    onSale ? 'line-through' : 'none'
                                  }
                                >
                                  {onSale ? regularPrice : price}
                                </Text>
                                {salePrice ? (
                                  <Text fontWeight="bold">{salePrice}</Text>
                                ) : null}
                              </Grid>
                              <Flex>
                                <Text>{quantity}</Text>
                              </Flex>
                            </Grid>
                          </Grid>
                        </Td>
                      </>
                    ) : (
                      <>
                        <Td>
                          <Grid gridGap={1}>
                            <Text fontWeight="bold">{name}</Text>
                            <Text>
                              {formattedSessions(courseDetails?.sessions)}
                            </Text>
                            <Text>
                              {courseDetails?.presentationMethod === 'Online'
                                ? courseDetails?.presentationMethod
                                : courseDetails?.locationShortname}
                            </Text>
                          </Grid>
                        </Td>
                        <Td>
                          <Grid gridGap="1">
                            <Text
                              textDecoration={onSale ? 'line-through' : 'none'}
                            >
                              {onSale ? regularPrice : price}
                            </Text>
                            {salePrice ? (
                              <Text fontWeight="bold">{salePrice}</Text>
                            ) : null}
                          </Grid>
                        </Td>
                        <Td>
                          <Flex justifyContent="flex-end">
                            <Text>{quantity}</Text>
                          </Flex>
                        </Td>
                      </>
                    )}
                    <Td textAlign="right" width="44">
                      {`£${parseInt(subtotal).toFixed(2)}`}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                {isSmallScreen ? null : (
                  <>
                    <Th border="none"></Th>
                    <Th border="none"></Th>
                  </>
                )}
                <Th color="grey.800" fontSize="md" py="4" textAlign="right">
                  Subtotal
                </Th>
                <Th
                  color="grey.800"
                  fontSize="md"
                  py="4"
                  textAlign="right"
                  width="44"
                >
                  {subtotal}
                </Th>
              </Tr>
              {parseInt(discountTotal) > 0 ? (
                <Tr>
                  {isSmallScreen ? null : (
                    <>
                      <Td border="none"></Td>
                      <Td border="none"></Td>
                    </>
                  )}
                  <Td color="grey.800" fontSize="md" py="4" textAlign="right">
                    Discount
                  </Td>
                  <Td
                    color="grey.800"
                    fontSize="md"
                    py="4"
                    textAlign="right"
                    width="44"
                  >
                    {`-${discountTotal}`}
                  </Td>
                </Tr>
              ) : null}
              <Tr>
                {isSmallScreen ? null : (
                  <>
                    <Td border="none"></Td>
                    <Td border="none"></Td>
                  </>
                )}
                <Td textAlign="right">Payment method</Td>
                <Td textAlign="right">{paymentMethodTitle}</Td>
              </Tr>
              <Tr>
                {isSmallScreen ? null : (
                  <>
                    <Th border="none"></Th>
                    <Th border="none"></Th>
                  </>
                )}
                <Th color="grey.800" fontSize="xl" py="4" textAlign="right">
                  Total
                </Th>
                <Th
                  color="grey.800"
                  fontSize="xl"
                  py="4"
                  textAlign="right"
                  width="44"
                >
                  {total}
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </Grid>
      </Container>
    </Layout>
  )
}

export default OrderReceivedPage
